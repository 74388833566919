@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Quicksand:wght@300;400;500;600;700&family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Mono&display=swap');

* {
  /* margin: 0; */
  /* padding: 0; */
  /* line-height: 1;  */
  /* scroll-behavior: smooth; */
}
body {
  margin: 0;
  font-family: 'Hanken Grotesk', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hidden{
  display:  none; 
}

.ant-layout .ant-layout-sider-light {
  box-shadow: 0 2px 6px rgba(61,65,67,.2);
  z-index: 4;
}
.ant-layout .ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 10px;
  inset-inline-end: -40px;
  z-index: 1;
  width: 40px;
  height: 40px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-start-start-radius: 0;
  border-start-end-radius: 6px;
  border-end-end-radius: 6px;
  border-end-start-radius: 0;
  cursor: pointer;
  transition: all 0.3s ease;
  color: rgba(0, 0, 0, 0.88);
  background: #ffffff;
  border: 1px solid #f5f5f5;
  border-inline-start: 0;
}
.ant-layout-header {
  display: flex;
  align-items: center;
}
.ant-layout-header {
  padding: 0 25px 0 60px;
}
.ant-layout-header, .ant-layout-content {
  background-color: transparent;
  position: relative;
}

.ant-table-wrapper .ant-table {
  background-color: transparent; 
}
.ant-table table {
  border-spacing:   0px 5px;
}

.ant-table-cell.ant-table-selection-column {
  border-spacing : initial;
}
.ant-table-cell .call-action {
  display: none;
}
.ant-table-cell.ant-table-cell-row-hover .call-action {
  display: block;
}

.ant-table .ant-table-header {
  position: relative;
  margin-bottom: -10px;
  margin-top: -10px;
  z-index: 1;
}
.ant-table .ant-table-footer {
  background-color: transparent;
}
.ant-table table tr {
  /* background-color: #fff; */
  border-radius: 4px;
  position: relative;
}
.ant-table table thead tr {
  box-shadow: 0 2px 6px rgba(61,65,67,.2);
}
.ant-table table tbody tr:hover {
  box-shadow: 0 4px 8px rgba(61,65,67,.2);
}
.ant-table-wrapper .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-thead >tr>td,
.ant-table-wrapper .ant-table-tbody >tr >th, .ant-table-wrapper .ant-table-tbody >tr >td {
  border-bottom: none;
  background-color: #fff;
  position: initial;
}
.ant-table-wrapper table tr td.ant-table-selection-column {
  opacity: 0;
  background-color: transparent;
}
.ant-table-wrapper table tr:hover td.ant-table-selection-column,
.ant-table-wrapper table tr.ant-table-row-selected td.ant-table-selection-column {
  opacity: 1;
  background-color: transparent;
}
.selectedRowsActions {
  position: absolute;
  width: calc(100% - 45px);
  height: 43px;
  margin-left: 45px;
  z-index: 11;
  display: flex;
  align-items: center;
  background-color: white;
}

.heading-with-tagline {
  display: flex;
  flex-direction: column;
}
.heading-with-tagline > :first-child {
  font-size: 14px;
  color: #383838;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: -3px;
}
.heading-with-tagline > :last-child {
  font-size: 12px;
  /* margin-top: 4px; */
  color: #6c6c6c;
  line-height: 1;
  font-weight: 600;
}

.avatar-with-profile {
  display: flex;
  align-items: center;
  gap: 12px;
}
.ant-btn .ant-btn-icon {
  line-height: 24px;
}

.ant-tabs >.ant-tabs-nav {
  flex: 1;
}
.ant-tabs .ant-tabs-content-holder {
  flex: 0;
}
.ant-tabs-left >.ant-tabs-nav .ant-tabs-tab {
  padding: 0;
  font-size: 15px;
  font-weight: 600;
  color: #3d3d3d;
}
.ant-tabs-left >.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
  margin: 6px 0 0 0;
}

.ant-btn.align {
  display: flex;
  align-items: center;
}
.ant-btn.bold {
  font-weight: 600;
}
.ant-form-item {
  margin-bottom: 10px;
  margin-top: 5px;
}
.ant-form-vertical .ant-form-item-label {
  padding-bottom: 4px;
  font-weight: 600;
}
.ant-form-item .ant-form-item-label >label {
  color: #242f56;
}
.ant-typography.ant-typography-danger {
  color: #cd3b3c !important;
}
.ant-typography.ant-typography-success {
  color: #449e18 !important;
}
.ant-progress .ant-progress-success-bg {
  box-shadow:  0px 0px 5px 5px #fff;
}
.ant-progress .ant-progress-inner {
  background-color: #e3e3e3;
}

.ant-menu-light.ant-menu-inline > .ant-menu-item {
  padding-left: 10px !important;
}
.ant-menu .ant-menu-item-group-title {
  padding: 8px 14px;
}
.ant-menu-sub {
  /* background-color: #f0f0f0 !important; */
}
.ant-menu-item[hidden] {
  display: none !important;
}

.ant-menu-submenu-selected .ant-menu-submenu-title {
  background-color: #f4faff;
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline >.ant-menu-submenu>.ant-menu-submenu-title {
  height: 32px;
  line-height: 32px;
}
.ant-menu .ant-menu-submenu-arrow {
  opacity: 0;
}

.menu-projectItem,
.menu-listItem {
  display: flex;
  align-items: center;
  gap: 5px;
}
.menu-projectItem-actions,
.menu-listItem-actions {
  position: absolute;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s linear;
  height: 100%;
  background-color: aliceblue;
  box-shadow: 0px 0px 30px -1px #ccc;
}
.menu-listItem-actions .ant-badge,
.menu-projectItem-actions .ant-badge {
  height: 100%;
  margin: 0 !important;
}
.menu-projectItem-actions .ant-badge .ant-badge-count,
.menu-listItem-actions .ant-badge .ant-badge-count {
  top: 14px;
  right: 12px;
  font-size: 10px;
}
.menu-projectItem-setting,
.menu-projectItem-chat,
.menu-listItem-setting,
.menu-listItem-chat {
  font-size: 14px !important;
  height: 100%;
  width: 32px;
  text-align: center;
  display: grid;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
}

.menu-projectItem-actions > span,
.menu-listItem-actions > span,
.ant-menu-submenu-selected .ant-menu-submenu-title .ant-badge {
  display: none;
}
.ant-menu-submenu-title:hover .menu-projectItem-setting,
.ant-menu-submenu-selected .ant-menu-submenu-title .menu-projectItem-setting,
.menu-projectItem-actions > .ant-badge,
.menu-listItem-actions > .ant-badge,
.ant-menu-item:hover .menu-listItem-chat,
.ant-menu-item-selected .menu-listItem-chat {
  display: grid;
}

.left-bg-overlay {
  position: absolute;
  top: -25px;
  left: 0;
  bottom: 0;
  width: 70px;
  background-color: #fafafa;
  box-shadow: 20px 0px 20px -30px #000;
  z-index: 3;
  display: flex;
  justify-content: end;
  align-items: center;
  flex-direction: column-reverse;
  gap: 10px;
  padding-bottom: 10px;
}

.avatar-picker {
  height: 0px;
  opacity: 0;
  overflow: hidden; 
  width: 100%;
  min-width: 220px;
  position: absolute;
  bottom: 0;
}
.avatar-picker .ant-select-selector .ant-select-selection-overflow{
  display: none;
}

.chat-container {
  position: absolute;
  z-index: 2;
  top: -0px;
  bottom: 0;
  left: 0px;
  width: calc(50% + 250px);
  border-radius: 0px;
  overflow: hidden;
  transform: translateX(-100%);
  background-color: #fafafa;
}
.chat-container.chatOpen {
  box-shadow: 69px 34px 95px 1px #e9e9e9;
  padding-left: 180px;
}
.chat-message {
  padding: 12px 15px;
  background-color: #dde5ff;
  background-color: lavender;
  border-radius: 0px 15px 15px 15px;
  margin-left: 40px;
  max-width: 80%;
  white-space: pre-wrap;
  font-size: 18px;
}
.chat-message.me {
  border-radius: 15px 15px 0px 15px;
  color: #232323;
  background-color: #e6f4ff;
}
.chat-message p {
  margin: 0;
}

.send-message-container {
  display: flex;
  align-items: end;
  gap: 10px;
  padding: 15px;
  box-shadow: 0px 0px 15px -10px #000;
}
.send-message-input {
  flex: 1;
  position: relative;
}
.send-message-input .emoji-btn {
  position: absolute;
  bottom: 4px;
  padding-top: 8px;
  left: 5px;
}
.emoji-picker {
  position: absolute;
  top: -25px;
  left: 0;
  transform: translateY(-100%);
}
.send-message-input .toolbar-btn {
  position: absolute;
  bottom: 4px;
  padding-top: 8px;
  right: 5px;
}
.send-message-actions {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
}
.quill {
  border: 1px solid #d3d3d3;
  border-radius: 23px;
  padding-left: 40px;
  padding-right: 50px;
  background-color: #f8fbff;
}
.ql-toolbar {
  display: none;
}
.show-toolbar .ql-toolbar {
  display: block;
  border: none;
  background-color: #ebebeb;
  border-radius: 35px;
  margin-left: -36px;
  margin-top: 5px;
  margin-right: -45px;
  margin-bottom: 3px;
  padding: 8px 16px;
}
.ql-container.ql-snow {
  border: none;
  font-size: 16px;
  font-family: inherit;
}
.ql-editor {
  max-height: 150px;
  overflow: scroll;
}
.chat-message p img,
.ql-snow .ql-editor img {
  height: 30px;
  margin: -2px 5px -9px;
  scale: 1;
}
.chat-message p.only-img img {
  width: 100px;
  height: auto;
}
.ant-btn-icon .anticon-send {
  margin-top: 3px;
  margin-left: 4px;
}

.ant-table-wrapper.report .ant-table-tbody > tr.ant-table-row.ant-table-row-level-1 > td {
  padding: 6px 16px;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}





/* MeetingCard.css */

.meeting-card-container {
  padding: 8px;
}

.meeting-card-container .meeting-card {
  /* background-color: #007ee3; */
  background-color: #e8e8e8;
  background-color: #e6f4ff;
  color: #000;
  margin: 0px 0 0px 0px;
  border-radius: 8px;
  padding: 8px 8px 8px;
  /* border: 1px solid #000; */
}

.meeting-card-container .actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.meeting-card-container .avatar-group {
  gap: 10px;
}

.meeting-card-container .bottom-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: 0px;
  border-radius: 0 0 6px 6px;
  overflow: hidden;
}

.meeting-card-container .bottom-button {
  flex: 1;
  border-radius: 0;
}

.meeting-card-container .logout-button {
  background-color: #f00;
}

.meeting-card-container .lk-control-bar {
  gap: 0;
  padding: 0;
}
.meeting-card-container .lk-button,
.meeting-card-container .lk-disconnect-button {
  padding: 8px;
  background-color: azure;
  border-radius: 6px;
  margin-left: 3px;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
}
.meeting-card-container .lk-disconnect-button {
  background-color: #dc2122;
  color: #fff;
}
.meeting-card-container .lk-button-group-menu {
  display: none;
}

.ant-avatar.active {
  position: relative;
  overflow: visible;
  /* margin-left: -4px;
  margin-right: 5px; */
}

.ant-avatar.active::before {
  content: '';
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  border-radius: 50%;
  border-top: 2px solid #ff398b;
  border-right: 2px solid #ff398b;
  border-bottom: 2px solid transparent;
  border-left: 2px solid #ff398b;
  animation: spin 1s linear infinite, colorChange 3s linear infinite;
  /* content: '';
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  border: 3px solid;
  border-color: #ff398b;
  background-color: #000;
  border-radius: 50%;
  animation: spin 2s linear infinite, colorChange 3s linear infinite;
  pointer-events: none; */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* @keyframes colorChange {
  0% {
    border-color: #2c3e50;
  }
  25% {
    border-color: #34495e;
  }
  50% {
    border-color: #1c2833;
  }
  75% {
    border-color: #2e4053;
  }
  100% {
    border-color: #2c3e50;
  }
} */


.day-bar {
  height: 10px;
  width: 100%;
  background-color: #e5e5e5;
  border-radius: 10px;
}

/* Timeline.css */
/* Assuming we have the following CSS */
.timeline-container {
  position: relative;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
}

.timeline-event {
  position: absolute;
  top: 0;
  height: 100%;
  /* background-color: #2196F3; */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  overflow: hidden;
  border-radius: 10px;
}
.timeline-event:hover {
  filter: drop-shadow(2px 4px 6px black);
}
.timeline-event.hover {
  filter: drop-shadow(2px 4px 6px black);
}

/* Add event-specific colors */
.checkedin {
  background-color: #3682f4;
}
.break {
  background-color: #ff9800;
}
.tracking {
  background-color: #4caf50;
}
.workTime {
  border-left: 1px dashed #000;
  border-right: 1px dashed #000;
  height: 49px;
  margin-top: -19px;
  position: absolute;
}